import { Link } from "react-router-dom";
import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import greenbagIcon from "../../svgs/greenbag.svg";
import { useTranslation } from "react-i18next";
import PubNub from "pubnub"
import {
  Forum,
  Send,
  Home,
  Notifications,
  Search,
  Settings,
} from "@mui/icons-material";
import {
  Avatar,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  Popover,
  useIsFocusVisible,
} from "@mui/material";
import { Box } from "@mui/system";
import { getAllUsersReadNotification } from "../../redux/action-creators/notification";
import { getAllUsersNotification } from "../../redux/action-creators/notification";
import { searchProfile } from "../../redux/action-creators/profileAction";
import { logoutUser } from "../../redux/action-creators/index";
import { debounce, map } from "lodash";
import { capitalize, isJsonString } from "../../utils/helper";
import { RoutingLinks, Type, empStr } from "../../utils/constants";
import Logo from "../../images/logo-petlover.png";
import defaultUserprofile from "../../images/defaultUserprofile.png";
import minImage from "../../images/minImage.png";
import { UserCommonStyles } from "../../styles/CommonStyles";
import { HeaderStyles } from "../../styles/Header";
import { getAllChats } from "../../redux/action-creators/chatActions";
import { IReceiverdata } from "../../utils/interfaceModel";
import { toast } from "../../utils/toastsMessage";

let shoppingCounter = JSON.parse(localStorage.getItem(Type?.shoppingBag) || '[]').length;
export const productCountHandler = (count: number) => shoppingCounter = count;

const pubnubPublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
const pubnubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
const Header = () => {

  // const [notiCount, setNotiCount] = useState();
  const isFocused = useIsFocusVisible()
  const wrapperRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const userId: number = Number(localStorage.getItem("userId"));
  const [allReceivers1, setAllReceivers1] = useState<IReceiverdata[]>([]);
  const [notificationCallIntervel, setNotificationCallIntervel] = useState<any>();
  const { t: translation } = useTranslation();
  const styleClasses = HeaderStyles();
  const commonStyle = UserCommonStyles();
  const [searchResult, setSearchResult] = useState([]);

  const userInfo = useSelector((state: RootStateOrAny) => {
    let userData = state.authReducer.authData;
    if (isJsonString(state.authReducer.authData)) {
      userData = JSON.parse(state.authReducer.authData);
    };
    return userData;
  });

  const notificationTotal = useSelector((state: RootStateOrAny) => state?.notificationReducer?.notificationCount);
  
  const [redirectProfile, setRedirectProfile] = useState(false);
  let redirectProfileLinking = `${RoutingLinks.editProfile}${userInfo.id}`;
  const [timeOutSearchState, setTimeOutSearchState] = useState(null);
  const [searchItem, setSearchItem] = useState({
    searchText: empStr,
    message: empStr,
  });

  // old notification function
  const getAllNotification = async () => {
    const data: any = await dispatch(getAllUsersReadNotification(userInfo?.id, true));
    if (data === "authentication_error") {
      clearInterval(notificationCallIntervel);
      await dispatch(logoutUser())
      history.push("/");
      return toast.error("Authentication Error")
    }
  };

  const getAllNotificationCount = useCallback(async () => {
    const result: any = await dispatch(getAllUsersNotification(userId));
    // debugger
    // if (result?.data && result?.data.length > 0) {
    //   setNotiCount(result.notificationCount);
    // }
  }, []);

  useEffect(() => {
    getAllNotificationCount();
  }, [isFocused, getAllNotificationCount, notificationTotal]);

  useEffect(() => {
    if (userInfo && (userInfo?.breed?.length === 0 || userInfo?.address?.length === 0)) {
      setRedirectProfile(true);
    } else {
      setRedirectProfile(false);
    };
  }, [userInfo]);


  // React.useEffect(() => {
  //   if (notificationCallIntervel) {
  //     clearInterval(notificationCallIntervel);
  //   }
  //   const InterelObj = setInterval(function () {
  //     if (document.hidden) {
  //       return;
  //     }
  //     const userid = localStorage.getItem("userId");
  //     if (userid) {
  //       // getAllNotification();
  //       getAllNotificationCount();
  //     };
  //   }, 60000);
  //   setNotificationCallIntervel(InterelObj);
  // }, []);

  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchResult([]);
          setSearchItem({
            searchText: "",
            message: "",
          });
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
* Get all receivers
*/
  const getReceivers = useCallback(async () => {
    const response: any = await dispatch(getAllChats(userInfo?.id));
    if (response?.isFound) {
      await setAllReceivers1(response?.data);
      // console.log("dataaaaaaaaa",response?.data)
    }
  }, [userInfo?.id, dispatch]);

  /**
 * Configure Pubnub with the keys
 * Used useMemo hook because the const is in the dependancy array of the useEffect and putting this outside the useMemo can make unnecessory renders
 */
  const pubnub = useMemo(() => {
    // console.log("MEMO")
    const pubnub_config = {
      publishKey: pubnubPublishKey,
      subscribeKey: pubnubSubscribeKey as string,
      heartbeatInterval: 20,

      setPresenceTimeout: 30,
      uuid: userInfo.email,
      logVerbosity: true,
      authKey: userInfo.token,
      ssl: true,
    };
    return new PubNub(pubnub_config);
  }, [userInfo.email, userInfo.token]);
  /**
* Fetching all users
*/
  useEffect(() => {
    getReceivers();
  }, [getReceivers]);

  /**
  * Added listener to get latest messages on real time
  */
  useEffect(() => {
    (async () => {
      const channels: (string | undefined)[] = map(allReceivers1, "channel");
      // const reciever=[...allReceivers]
      // Subscribe pubnub service for all channels
      // console.log("WELCOME")
      await pubnub.subscribe({
        channels: channels as string[],
        withPresence: true,
      });
    })();
  }, [allReceivers1]);

  /**
   * search on handle change
   * @param event
   * @returns
   */
  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    const searchKeyword = event.target.value.trimStart();
    setSearchResult([]);

    setSearchItem({
      searchText: searchKeyword,
      message: "",
    });

    if (timeOutSearchState) {
      clearInterval(timeOutSearchState);
    };

    if (searchKeyword === "") {
      return null;
    };
    let result: any = setTimeout(async () => {
      await getSearchResult(searchKeyword);
    }, 800);
    setTimeOutSearchState(result);
  };

  /**
   * get search result
   */
  const getSearchResult = debounce(async (searchTerm: string) => {
    const response: any = await dispatch(searchProfile(searchTerm));
    if (response && response?.length > 0) {
      setSearchResult(response);
    } else {
      setSearchItem((prevState) => ({
        ...prevState,
        message: translation("no_user"),
      }));
    }
  }, 1000);

  /**
   * action on click
   */
  const onclickAction = () => {
    setSearchResult([]);
    setSearchItem((prevState) => ({
      ...prevState,
      searchText: "",
    }));
  };
  const [anchorSetting, setAnchorSetting] = React.useState<HTMLButtonElement | null>(null);
  const handleSettingClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSetting(event.currentTarget);
  };

  const handleSettingClose = () => {
    setAnchorSetting(null);
  };

  const handleLogout = async () => {
    await dispatch(logoutUser());
  };

  const openSetting = Boolean(anchorSetting);

  return (
    <Box className={styleClasses.header}>
      <Container maxWidth="xl">
        <Grid container spacing={2} className={commonStyle.alignItemsCenter}>
          <Grid item xs={3} className={commonStyle.pt0}>
            <Box className={styleClasses.logo}>
              <Link
                to={{
                  pathname: redirectProfile
                    ? redirectProfileLinking
                    : RoutingLinks.home,
                }}>
                <img src={Logo} alt={translation("logo")} />
              </Link>
            </Box>
          </Grid>

          <Grid item xs={3} className={commonStyle.pt0}>
            <Box
              className={`${styleClasses.headerMenu} ${commonStyle.textCenter}`}
            >
              <Link
                to={{
                  pathname: redirectProfile
                    ? redirectProfileLinking
                    : RoutingLinks.home,
                }}
              >
                <Home />
              </Link>

              <Link
                to={{
                  pathname: redirectProfile
                    ? redirectProfileLinking
                    : RoutingLinks.notification,
                }}
              >
                <div>
                  {/* Your other content here */}
                  {notificationTotal && notificationTotal > 0 ? (
                    <div className={`${commonStyle.notificationBadgeStyle}`}>
                      {notificationTotal}
                    </div>
                  ) : null}
                </div>
                <Notifications />
              </Link>
              <Link
                to={{
                  pathname: redirectProfile
                    ? redirectProfileLinking
                    : RoutingLinks.chat,
                }}
              >
                <Forum />
              </Link>
            </Box>
          </Grid>

          <Grid item xs={6} className={commonStyle.pt0}>
            <Box className={commonStyle.flexEnd}>
              {/* Shopping cart icon area */}
              <Box className={`${styleClasses.headerMenu} ${commonStyle.textCenter} ${commonStyle.hoverTrigger}`}>
                <Link
                  onClick={() => localStorage.removeItem(Type?.shoppingCartCheckout)}
                  to={{
                    pathname: redirectProfile
                      ? redirectProfileLinking
                      : RoutingLinks.checkout_step1, // /shopping-cart
                  }}>

                  {shoppingCounter > 0 ? (
                    <div
                      // className={`${commonStyle.cartBadgeContainer}`}
                      style={{
                        width: shoppingCounter > 9 ? "20px" : shoppingCounter > 99 ? "24px" : "18px",
                        height: shoppingCounter > 9 ? "20px" : shoppingCounter > 99 ? "24px" : "18px",
                        borderRadius: '100%',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        backgroundColor: "#D95557",
                        top: shoppingCounter > 9 ? 22 : 23,
                        right: shoppingCounter > 9 ? 15 : 17,
                        zIndex: 99
                      }}
                    >
                      <p className={`${commonStyle.cartBadgeStyle}`}>
                        {shoppingCounter > 0 ? shoppingCounter : ""}
                      </p>
                    </div>
                  ) : null}
                  <img src={greenbagIcon} alt="Shopping Bag" />
                </Link>
              </Box>
              <Box className={`${commonStyle.headerSearch} ${commonStyle.ml10} ${commonStyle.mr10}`}>
                <TextField
                  onChange={(e) => handleSearchChange(e)}
                  placeholder={translation("search")}
                  value={searchItem.searchText}
                ></TextField>
                <Search />
                {searchResult?.length ? (
                  <ul className={commonStyle.autocompleteList} ref={wrapperRef}>
                    <li>
                      {searchResult.map((item, index) => (
                        <Link
                          key={index}
                          to={{
                            pathname: `${RoutingLinks.profiles}${item.id}`,
                          }}
                          onClick={() => onclickAction()}
                        >
                          {capitalize(item.first_name) +
                            " " +
                            capitalize(item.last_name)}
                        </Link>
                      ))}
                    </li>
                  </ul>
                ) : searchItem.searchText.length > 0 ? (
                  <ul className={commonStyle.autocompleteList}>
                    <li className={commonStyle.p20}>
                      {searchItem.message
                        ? searchItem.message
                        : translation("searching")}
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </Box>

              <Box className={`${commonStyle.ml10} ${commonStyle.mr10}`}>
                <Link
                  to={RoutingLinks.userProfile}
                  className={`${styleClasses.headerUser} ${commonStyle.flexCenter}`}
                >
                  <Avatar
                    alt={translation("Remy_Sharp")}
                    src={
                      userInfo?.profile_image
                        ? userInfo.profile_image
                        : defaultUserprofile
                    }
                    className={commonStyle.mr10}
                  />
                  <Typography>
                    {`${userInfo?.first_name
                      ? capitalize(userInfo.first_name)
                      : ""
                      } ${userInfo?.last_name ? capitalize(userInfo.last_name) : ""
                      }`}
                  </Typography>
                </Link>
              </Box>
              <Box
                className={`${commonStyle.settingsBtn} ${commonStyle.ml10} ${commonStyle.mr10}`}
              >
                <IconButton
                  className={commonStyle.iconBtn}
                  onClick={handleSettingClick}
                >
                  <Settings className={commonStyle.white} />
                </IconButton>

                <Popover
                  open={openSetting}
                  anchorEl={anchorSetting}
                  onClose={handleSettingClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ul
                    className={`${styleClasses.settingPopoverList} ${commonStyle.removeListStyle}`}
                  >
                    <li onClick={handleSettingClose}>
                      <Link to={RoutingLinks.setting}>
                        {translation("setting")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={handleLogout}>
                        {translation("logout")}
                      </Link>
                    </li>
                  </ul>
                </Popover>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Header;
