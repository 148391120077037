const postImagesBucketName = process.env.REACT_APP_BUCKET_NAME;
const ImageTypes = ["jpg", "jpeg", "png", "gif", "bmp", "heif"];
const VideoTypes = ["mp4", "mov", "wmv", "avi", "webm", "mpeg", "flv", "hevc"];
const AudioTypes = ["wav", "aif", "mp3", "mid"];
const FileTypes = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt"];
const PdfTypes = ["pdf"];
const ImgTypeArray = ["jpg", "jpeg", "png", "gif"];
const VideoTypeArray = ["mp4", "mov", "wmv", "avi", "webm", "mpeg", "flv", "quicktime"];

const AcceptedExtensionsInChat =
  ".jpg, .jpeg, .png, .gif, .bmp, .mp4, .mov, .wmv, .avi, .webm, .mpeg, .flv, .wav, .aif, .mp3, .mid, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .pdf";

const ImageExtentions = ".jpg, .jpeg, .png, .gif, .bmp, .heif";
const VideoExtentions = ".mp4,.mov, .wmv, .avi, .webm, .mpeg, .flv, .hevc";
const AudioExtentions = ".wav, .aif, .mp3, .mid, .aac";
const DocumentExtentions = ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .pdf";

const Categories = [
  "Dog",
  "Cat",
  "Rabbit",
  "Bird",
  "Horse",
  "Fish",
  "Reptiles",
  "Small Pets",
  "others",
];
const gender = ["male", "female", "any"];
const language = ["English", "French", "German", "Spanish"];
const Privacy = ["Public", "Friends"];

const ProductCategory = ["Food", "Toys", "Beds", "Clothing"];

const Region = ["Within 100 miles", "Complete platform"];
const Availability = ["Within 100 miles", "Globally"];


const Reason = ["Marketing", "Platform Traffic", "Engagement", "Messaging"];

const locationPrediction = {
  predictions: [],
  location: "",
  latitude: 0,
  longitude: 0,
};

const empStr = "";

const showreplystate = {
  parent_id: 0,
  post_id: 0,
};
const showEditstate = {
  parent_id: 0,
  post_id: 0,
  post_userId: 0
};

const editPostIdstate = {
  postId: 0,
  postIndex: 0,
  userReaction: "0",
  post_userId: 0,
};

const commentOnPoststate = {
  post_id: 0,
  comment: "",
  parent_id: 0,
  post_userId: 0,
};

const Type = {
  product: "product",
  adoption: "adoption",
  "lost&found": "lost&found",
  lost_found: "lost&found",
  cat: "Cat",
  dog: "Dog",
  friendrequest: "Friend Request",
  image: "image",
  video: "video",
  audio: "audio",
  pdf: "pdf",
  text: "text",
  otherdoc: "other_document",
  homePost: "post", 
  tricks: "tricks",
  chatRoom: "chatRoom",
  profile: "profile",
  tails: "tails",
  postDetail: "postDetail",
  shoppingBag: "shoppingBag",
  shoppingCartCheckout: "shoppingCartCheckout"
};

const facebooklink = "https://connect.facebook.net/en_US/sdk.js";
const NewLineRegex = /\r?\n/g;
const AlphabateRegex = /\b[a-z]/g;
const RoutingLinks = {
  comments: "/comment/",
  friends: "/friends",
  stories: "/stories",
  friendsList: "/friends/",
  helpSupport: "/help-center",
  productDetail: "/product-detail/",
  favouritesList: "/favourites",
  petPlayDate: "/pet-play",
  setting: "/settings",
  orders: "/orders",
  sellerOrdersDetail: "/seller-orders-detail/",
  buyerOrdersDetail: "/buyer-orders-detail/",
  message: "/messages",
  categoryDetail: "/category-detail",
  category: "/category",
  lostFound: "/pet-lost-found",
  adoption: "/adoption",
  marketplace: "/products",
  ad: "/ad-center",
  lostFoundlist: "/lost-found-list",
  addLostFound: "/add-lost-found",
  adoptionList: "/adoption-list",
  adoptionRequest: "/adoption-request/",
  addyours: "/add-yours",
  addProduct: "/add-product",
  sellersList: "seller-profile",
  createPostImg: "create-post-image",
  petoftheDay: "/pet-of-the-day/",
  adoptionDetail: "/adoption-detail/",
  lostFoundDetail: "/lost-found-detail/",
  createPostPreview: "/create-post-preview",
  createPost: "/create-post",
  notification: "/notifications",
  tagFriendList: "/tag-friends",
  editProfile: "/edit-profile/",
  profiles: "/profile/",
  userProfile: "/profile",
  home: "/home",
  tricks: "/tricks",
  chatRoom: "/chatRoom",
  tails: "/tails",
  serach: "/search",
  chat: "/chat",
  verifyEmail: "/verify-email",
  signup: "/signup",
  login: "login",
  createSeller: "/create-seller",
  addbalance: "/addbalance",
  viewBalance: "/view-balance",
  adlist: "/adlist",
  adcenter: "/adcenter",
  editAd: "/edit-ad/",
  adStatsGraph: "/ad-stats-graph/",
  adPromote: "/promote-ad/",
  payment: "/payment",
  aboutUs: "/Aboutus",
  postDetail: "/post-detail/",
  EditProduct: "/edit-Product/",
  MyProduct: "/my-product/",
  checkout_step1: "/shopping-cart",
  checkout_step2_shipping: "/shipping-address",
  checkout_step3_payment: "/product-payment"
};

const friendRequest = "Friend Request";
const acceptFriend = "Accept Friend";
const addComment = "Add Comment";
const taggedFriend = "Tagged Friend";
const Like = "Like";
const approveAdd = "Ad_approve";
const rejectAdd = "Ad_reject";
const add_complete = "Ad_complete";
const ad_completeion24 = "ad_completeion24";
const Insufficient_amount = "Insufficient_amount";
const Seller_approve = "Seller_approve";
const new_order = "New order";
const order_placed = "Order placed";
const client_order = "client_order";
const pet_found = "Pet Found";
const pet_adopt = "pet_adopt";

export {
  postImagesBucketName,
  ImageTypes,
  VideoTypes,
  AudioTypes,
  FileTypes,
  PdfTypes,
  AcceptedExtensionsInChat,
  NewLineRegex,
  ImageExtentions,
  VideoExtentions,
  AudioExtentions,
  DocumentExtentions,
  friendRequest,
  acceptFriend,
  addComment,
  taggedFriend,
  Like,
  Categories,
  RoutingLinks,
  ImgTypeArray,
  VideoTypeArray,
  Region,
  locationPrediction,
  empStr,
  gender,
  showreplystate,
  showEditstate,
  editPostIdstate,
  commentOnPoststate,
  Type,
  language,
  Privacy,
  ProductCategory,
  AlphabateRegex,
  facebooklink,
  approveAdd,
  rejectAdd,
  add_complete,
  ad_completeion24,
  Reason,
  Insufficient_amount,
  Seller_approve,
  Availability,
  new_order,
  order_placed,
  client_order,
  pet_found,
  pet_adopt
};
