import { Dispatch } from "redux";
import { ActionType } from "../action-types/index";
import axios from "../../services/axiosService";
const URL = process.env.REACT_APP_API_URL;

/**
 * search profile
 * @param searchKeyword
 * @returns
 */
export const searchProfile =
  (searchKeyword: string) => async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(
        `${URL}/api/v1/profile/search/${searchKeyword}`
      );
      return response.data.data.data;
    } catch (error) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };

/**
 * get profile
 * @param id
 * @returns
 */
export const getProfile =
  (id: number, userId?: number) => async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`${URL}/api/v1/profile/${id}`);
      if (userId === id) {
        dispatch({
          type: ActionType.UPDATEPROFILE,
          payload: response.data.data.data[0],
        });
      }

      return response.data.data.data;
    } catch (error) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };

/**
 * get faqs
 * @param offset
 * @returns
 */
export const getFaq = (offset: number) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(
      `${URL}/api/v1/admin/faqs/list?offset=${offset}`
    );
    return response.data.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * get friends list
 * @param userId
 * @returns
 */
export const getFriendsList =
  (userId: number, offset?: number) => async (dispatch: Dispatch) => {
   
    try {
      const response = await axios.get(
        `${URL}/api/v1/profile/friends_list/${userId}/${offset}/`
      );
      return response.data.data.data;
    } catch (error) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };

/**
 * search friend profile by id
 * @param userId
 * @param searchKeyword
 * @returns
 */
export const SearchFriendProfileById =
  (userId: number, searchKeyword: string) => async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(
        `${URL}/api/v1/profile/searchFriend/${searchKeyword}/${userId}`
      );
      return response.data.data.data;
    } catch (error) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };

/**
 * update profile
 */
export const updateProfile =
  (data: object, id: number) => async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        `${URL}/api/v1/profile/update/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };

/**
 * details of googleplaces
 * @param placeId
 * @returns
 */
export const GooglePlacesDetail =
  (placeId: string) => async (dispatch: Dispatch) => {
    try {
      const res = await axios.get(
        `${URL}/api/v1/profile/googlePlace/${placeId}`
      );
      return res.data;
    } catch (e) {
      await dispatch({ type: "LOADING_FAILURE" });
    }
  };

/**
 * update settings
 * @param data
 * @param id
 * @returns
 */
export const updateSetting =
  (data: object, id: number, notification: number) =>
  async (dispatch: Dispatch) => {
    try {
      data["notification"] = notification;
      const response = await axios.patch(
        `${URL}/api/v1/profile/updatesetting/${id}`,
        data
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };

/**
 * update password
 * @param data
 * @param id
 * @returns
 */
export const updatePassword =
  (data: object, id: number) => async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        `${URL}/api/v1/profile/updatePassword/${id}`,
        data
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };

export const getpageDetail = (pageId: Number) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(`${URL}/api/v1/admin/pages/${pageId}`);
    return response.data.data;
  } catch (error) {
    dispatch({
      type: ActionType.FAILURE,
    });
  }
};

/**
 * search friend profile by id
 * @param userId
 * @param searchKeyword
 * @returns
 */
export const getPetOfthedayofuser =
  (userId: number) => async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(
        `${URL}/api/v1/profile/getPetOftheDaydetails/${userId}`
      );
      return response.data.data.data;
    } catch (error) {
      dispatch({
        type: ActionType.FAILURE,
      });
    }
  };
